<template>
  <div class="HomeScreen">
    <h1>SISSy – Sedan Informations- und Stundenplansystem</h1>
    <router-link to="/">Home</router-link> |
    <router-link to="/ids">Infodisplay Schüler</router-link>|
    <router-link to="/idl">Infodisplay Lehrer</router-link>|
    <router-link to="/about">About</router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeScreen',
  props: {}
}
</script>